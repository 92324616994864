<template>
  <div class="home">
    <Header />
    <div class="container">

	<center>
		<br/><br/><p>Enter your <b>Username</b> and <b>Password</b> to sign in to the <b>Jacinta Gallant</b> Content Management System.</p><br/><br/>

		<div class="sign-in">
			<div class="row">
						<div class="body">
							<div class="row-fluid">
								<div class="span7">
									<div class="sign-in-form">
										<form name="login" action="login_exec.php" method="post">
											<label for="inputEmail3" class="logintextcolor col-sm-20 control-label">Username </label>
											<br/>
											<input class="login form-field" name="username" placeholder="Username" type="text"/>
											<br/><br/>
											<label for="inputEmail3" class="logintextcolor col-sm-20 control-label">Password </label>
											<br/>
											<input class="login form-field" name="password" placeholder="Password" type="password"/>
											<br/><br/>
											<center><button type="submit" class="btn-orange btn btn-default">SIGN IN</button></center>
											<br/><br/>
										</form>
									</div>
								</div>
							</div>
						</div>
				
			
			</div>
		</div>
	</center>

</div>
<Footer />
  </div>
</template>

<script>
// Components
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'

export default {
  name: 'cmsHome',
  components: {
    Header,
    Footer
  },
  data() {
    return {
    
    }
  }
}
</script>

<style lang="scss">

</style>